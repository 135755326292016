<template>
  <CCard>
    <CCardBody>
      <h1 class="pb-3">Помощь</h1>
      <div>
        <CLink target="_blank" :href="urls.urlDocumentation">
          Документация
        </CLink>&nbsp;Инструкция по установке, настройке и использованию приложения
      </div>
      <div>
        <CLink :href="'mailto:'+urls.urlMailtoSupport">
          {{ urls.urlMailtoSupport }}
        </CLink>&nbsp;Техническая поддержка, индивидуальные доработки
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import urls from "../../../router/urls";

export default {
  name: 'Index',
  computed: {
    urls() {
      return urls
    }
  },
};
</script>

<style scoped>

</style>
